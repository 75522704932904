import tolkien from './404-tolkien.webp'
import contact from './aboutus-contact.webp'
import bigcar from './booking-big-car.svg'
import downarrow from './booking-down-arrow.svg'
import smallcar from './booking-small-car.svg'
import suvcar from './booking-suv-car.svg'
import building from './corporate-building.webp'
import faq from './faq-question.webp'
import instagram from './footer-instagram.svg'
import linkedin from './footer-linkedin.svg'
import phone from './footer-phone.svg'
import twitter from './footer-twitter.svg'
import whatsapp from './footer-whatsapp.svg'
import youtube from './footer-youtube.svg'
import bull from './franchising-bull.webp'
import bull1 from './franchising-bull-1.webp'
import bull2 from './franchising-bull-2.webp'
import background from './home-background.webp'
import logo from './home-logo.webp'
import car from './latest-car.webp'
import basic from './services-car-basic-valet.webp'
import full from './services-car-full-valet.webp'
import ultimate from './services-car-ultimate-valet.webp'
import carhome from './home-car1.webp'

// eslint-disable-next-line
export default {
  tolkien,
  contact,
  bigcar,
  downarrow,
  smallcar,
  suvcar,
  building,
  faq,
  instagram,
  linkedin,
  phone,
  twitter,
  whatsapp,
  youtube,
  bull,
  bull1,
  bull2,
  background,
  logo,
  car,
  basic,
  full,
  ultimate,
  carhome,
}
