import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Home } from './Home'
import Booking from './Booking'
import { Services } from './Services'
import { Franchising } from './Franchising'
import { FrequentlyAskedQuestions } from './FrequentlyAskedQuestions'
import { Corporate } from './Corporate'
import { AboutUs } from './AboutUs'
import { NavigationBar } from './components/NavigationBar'
import { Footer } from './components/Footer'
import { NoMatch } from './NoMatch'
import { Test } from './Test'
import Latest from './Latest'
import { HelmetProvider } from 'react-helmet-async'

const GA_TRACKING_ID = 'G-RT5H4DVZ7F' // Replace with your own Google Analytics tracking ID

ReactGA.initialize(GA_TRACKING_ID)

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <React.Fragment>
      <Router>
        <HelmetProvider>
          <NavigationBar />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  title="Mobile Car Valeting and Wash Services - Get Your Car Looking Like New"
                  description="Get a like-new car with our mobile valeting and car wash services. We offer convenient options for car clean, valeting service, and mobile car washing."
                />
              }
            />
            <Route
              path="/Booking"
              element={
                <Booking
                  title="Book Your Mobile Car Valeting and Car Wash Services Today"
                  description="Experience ultimate convenience with our expert mobile car valeting and wash services. Book now for top-quality car clean, valeting service, and mobile car washing."
                />
              }
            />
            <Route
              path="/Services"
              element={
                <Services
                  title="Mobile Car Valeting, Car Wash Services Convenient and Flexible"
                  description="Experience the convenience of expert mobile car valeting and car wash services. Book now for car clean, valeting service, and mobile car washing that will leave your car looking like new."
                />
              }
            />
            <Route
              path="/Franchising"
              element={
                <Franchising
                  title="Join Our Mobile Car Detailing and Valeting Business Opportunities"
                  description="Join the mobile car wash revolution and become a franchise partner with us. Discover the benefits of owning your own business and delivering premium car washing services to customers. Contact us to learn more."
                />
              }
            />
            <Route
              path="/Corporate"
              element={
                <Corporate
                  title="Expert Car Detailing and Valeting for Corporate Clients"
                  description="We're passionate about providing top-quality car cleaning services. Our valeting team specializes in detailing services, mobile valeting, and car wash solutions."
                />
              }
            />
            <Route
              path="/FrequentlyAskedQuestions"
              element={
                <FrequentlyAskedQuestions
                  title="FAQs About Our Mobile Car Cleaning Services"
                  description="Get answers to your car cleaning and franchise questions on our FAQ page. Discover more about our detailing services, mobile valeting, and car wash solutions."
                />
              }
            />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route
              path="/Test"
              element={<Test title="Test" description="Test Description" />}
            />
            <Route
              path="/Latest"
              element={
                <Latest
                  title="Stay Up-to-Date with the Latest Car Cleaning Trends and Tips"
                  description="Get the latest car cleaning trends, tips, and franchise opportunities on our Latest News & Articles page. Expert advice on detailing services, mobile valeting, and car wash solutions."
                />
              }
            />
            <Route
              path="*"
              element={
                <NoMatch
                  title="404 Error - Page Not Found | Mobile Mint Car Care"
                  description="Oops! The page you're looking for isn't here. Please check the URL or return to our homepage to find the information you need."
                />
              }
            />
          </Routes>
          <Footer />
        </HelmetProvider>
      </Router>
    </React.Fragment>
  )
}

export default App
