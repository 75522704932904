import React from 'react'
import assets from './assets'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description: string
}

export const Corporate: React.FC<Props> = ({ title, description }) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="hero">
        <h1>
          Expert Car Detailing and <br />
          Valeting for Corporate Clients
        </h1>
        <p>
          <b>Mobile car detailing</b> services have become increasingly popular
          as people seek the convenience of having their cars cleaned at their
          homes or workplaces. Our <b>valeting service</b> offers customizable
          solutions for businesses with fleets of vehicles, providing expert{' '}
          <b>car detailing</b> and valeting for corporate clients. We specialize
          in <b>mobile valeting</b>, bringing our professional{' '}
          <b>car wash solutions</b> to your doorstep, whether you need a basic{' '}
          <b>car wash</b> or a <b>full detailing service</b>. Our{' '}
          <b>mobile car cleaning services</b> are perfect for busy individuals
          who don't have time to visit a <b>car wash</b>, or for corporate
          clients who need to keep their fleets looking clean and professional.
          Contact us to learn more about our <b>car clean services</b> and
          franchising opportunities.
        </p>
      </div>
      <section className="hero is-fullheight-with-navbar">
        <div className="columns is-vcentered">
          <div className="column fadeLeftMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.building}
                alt="Mobile Mint Car Care - Corporate"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
              />
            </figure>
          </div>
          <div className="column fadeRightMini">
            <h2>Our Mission</h2>
            <p>
              At <b>Mobile Mint Car Care</b>, our mission is to provide our
              customers with the highest quality car care services, using the
              latest technology and techniques. We are committed to providing
              our franchisees with the support and resources they need to grow
              and succeed in their businesses, and to building a strong,
              sustainable company for the future.
            </p>
            <hr />
            <h3>Our Values</h3>
            <li>
              Quality: We are committed to providing the highest quality
              services to our customers.
            </li>
            <li>
              Reliability: We are dedicated to providing reliable and dependable
              services to our customers.
            </li>
            <li>
              Innovation: We are constantly looking for new and better ways to
              serve our customers and franchisees.
            </li>
            <li>
              Collaboration: We believe in working together with our franchisees
              and customers to achieve our common goals.
            </li>
            <hr />
            <h4>Our Management Team</h4>
            <p>
              <b>Mobile Mint Car Care</b> is led by a highly experienced
              management team, who bring a wealth of knowledge and expertise to
              the company. Our management team is committed to the success of
              the company and to supporting our franchisees in their businesses.
            </p>
          </div>
        </div>
        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}
