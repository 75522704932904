import React from 'react'
import assets from './assets'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description: string
}

export const Home: React.FC<Props> = ({ title, description }) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <div className="hero">
        <h1>
          Mobile Car Valeting <br />
          Wash Services <br />
          Get Your Car Looking Like New
        </h1>
      </div>

      <section className="hero is-fullheight-with-navbar mb-5">
        <div className="columns is-vcentered">
          <div className="column fadeLeftMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.carhome}
                alt="Mobile Mint Car Care - Basic Valet | Full Valet | Ultimate Valet"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
                loading="lazy"
              />
            </figure>
          </div>

          <div className="column fadeRightMini">
            <p>
              Revitalise your vehicle's appearance with our{' '}
              <b>mobile car valeting</b>, <b>mobile car wash</b>, and{' '}
              <b>detailing services</b>. Our convenient and flexible services
              cater to your needs, including <b>car washing</b>, interior
              cleaning, and full valet services. Our professional team utilises
              top-quality products and techniques to leave your car shining and
              fresh. Whether you need a <b>mobile car wash</b>, <b>car clean</b>
              , or car <b>valeting service</b>, our experts have you covered.
            </p>
            <h2>
              <span className="fire">
                Expert Mobile Detailing Services - Car Valeting, Car Wash, and
                More
              </span>
            </h2>
            <p>
              Our <b>mobile car valeting</b> company specialises in providing
              on-the-go car cleaning services. Our team of skilled professionals
              arrives at your preferred location to give your vehicle the
              attention it deserves. We offer mobile <b>car valeting</b>{' '}
              services for all vehicles. Choose from a variety of options to
              suit your budget and schedule. Say goodbye to the hassle of
              traditional <b>car washes</b> and book our mobile{' '}
              <b>car valeting</b> and wash services today.
            </p>{' '}
            <h3>
              <span className="fire">
                Hassle-Free Car Cleaning - Try Our Convenient Mobile Car
                Valeting Services
              </span>
            </h3>
            <p>
              Trust us for all your <b>car detailing</b> needs. Our{' '}
              <b>mobile valeting service</b>, <b>mobile car wash</b>,{' '}
              <b>car valeting</b>, and <b>car clean</b> services will bring your
              car back to life. Contact us now and see the difference our
              services can make.
            </p>
            <p>
              In addition to our top-notch cleaning services, we also offer{' '}
              <span className="fire">
                complimentary tire pressure check and windscreen fluid topping
                up
              </span>{' '}
              to make sure that your car is in tip-top shape.
            </p>
            <p>
              Book your appointment with us today and see the Mobile Mint Car
              Care difference for yourself!
            </p>
            <button
              className="button is-medium btn"
              title="Booking"
              onClick={(event) => (window.location.href = '/Booking')}
            >
              Booking
            </button>
          </div>
        </div>
        <hr />

        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}
