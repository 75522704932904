import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import assets from './assets'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description: string
}

export const FrequentlyAskedQuestions: React.FC<Props> = ({
  title,
  description,
}) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="hero">
        <h1>
          FAQs About <br />
          Our Mobile Car Cleaning Services
        </h1>
      </div>
      <section className="hero is-fullheight-with-navbar">
        <div className="columns is-vcentered">
          <div className="column fadeLeftMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.faq}
                alt="Mobile Mint Car Care - FAQs"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
              />
            </figure>
          </div>
          <div className="column fadeRightMini">
            <div>
              <p>
                At Mobile Mint Car Care, we're committed to providing
                exceptional service and helping our customers make informed
                decisions about our <b>car cleaning services</b> and franchise
                opportunities. That's why we've put together a comprehensive FAQ
                page to answer some of the most commonly asked questions.
                Discover more about our <b>detailing services</b>,{' '}
                <b>mobile valeting</b>, and <b>car wash solutions</b>, and learn
                about our franchise opportunities. We're always here to help
                you, so if you can't find the answers you're looking for, please
                don't hesitate to contact us.
              </p>
              <p>
                Our FAQ page covers a range of topics, including pricing, the
                benefits of our services, and how to become a franchisee. You'll
                find information about our range of <b>detailing services</b>,
                from
                <b>interior and exterior cleaning</b> to <b>paint protection</b>{' '}
                and restoration. We're also happy to answer questions about our
                <b>mobile valeting services</b>, which bring the car wash to
                your doorstep. And if you're interested in starting your own
                business, we have details on our franchise opportunities and
                what it takes to join our team. Visit our FAQ page to learn
                more.
              </p>
              <p>
                At Mobile Mint Car Care, we understand that our customers have
                unique needs and preferences, which is why we offer a range of
                customizable services. Whether you're looking for a one-time
                cleaning or ongoing maintenance, our team can work with you to
                develop a plan that meets your specific needs. We're also
                committed to providing top-quality service and using only the
                best products and equipment, so you can be confident that your
                car is in good hands. Visit our FAQ page to learn more about our
                car cleaning services and franchise opportunities, and contact
                us if you have any additional questions or to schedule an
                appointment.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column is-one-quarter"></div>
          <div className="column">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What services does Mobile Mint Car Care offer?
                </Accordion.Header>
                <Accordion.Body className="gnrl">
                  Mobile Mint Car Care offers wide range of car detailing
                  services including <b>basic valet</b>, <b>full valet</b>,{' '}
                  <b>ultimate valet</b>, <b>compound clay and colour</b>,{' '}
                  <b>polish</b>, and <b>wax</b>, all performed on-the-go to meet
                  your convenience, as well as complimentary tire pressure
                  checks and windscreen fluid top-ups.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How do I book a service with Mobile Mint Car Care?
                </Accordion.Header>
                <Accordion.Body className="gnrl">
                  You can book a service with us through our online{' '}
                  <a
                    className="t3"
                    href="/Booking"
                    target="_self"
                    title="Booking"
                  >
                    Booking Form
                  </a>{' '}
                  on our website. Simply select the service you want, choose a
                  date and time that works for you, and complete the form with
                  your contact information.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Are your services available for both private and commercial
                  vehicles?
                </Accordion.Header>
                <Accordion.Body className="gnrl">
                  Yes, our services (Basic Valet, Full Valet and Ultimate Valet,
                  etc.) are available for both private and commercial vehicles.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Do you offer mobile services?
                </Accordion.Header>
                <Accordion.Body className="gnrl">
                  Yes, we currently only offer our services at your location.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Can you tell me more about your franchising opportunity?
                </Accordion.Header>
                <Accordion.Body className="gnrl">
                  Soon, We will offer franchising opportunities to entrepreneurs
                  who are interested in expanding the Mobile Mint Car Care
                  brand. As a franchisee, you'll receive training, support, and
                  resources from us to help you run a successful business. If
                  you're interested in learning more, please visit our
                  Franchising page on our website for more information.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  How much does it cost to become a Mobile Mint Car Care
                  franchisee?
                </Accordion.Header>
                <Accordion.Body className="gnrl">
                  The cost to become a Mobile Mint Car Care franchisee will
                  depend on several factors, including the location you choose
                  and the size of the operation. We provide detailed information
                  on the investment required to become a franchisee on our
                  Franchising page.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  What kind of training and support will I receive as a Mobile
                  Mint Car Care franchisee?
                </Accordion.Header>
                <Accordion.Body className="gnrl">
                  As a Mobile Mint Car Care franchisee, you'll receive
                  comprehensive training and support in all areas of the
                  business, including marketing, operations, and customer
                  service. We'll also provide ongoing support to help you run a
                  successful franchise.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  How can I get in touch with Mobile Mint Car Care to learn more
                  about franchising?
                </Accordion.Header>
                <Accordion.Body className="gnrl">
                  You can get in touch with us through the{' '}
                  <a
                    className="t3"
                    href="/AboutUs"
                    target="_self"
                    title="Contact"
                  >
                    Contact Form
                  </a>{' '}
                  on our website. Simply fill out the form with your contact
                  information and a brief message, and we'll get back to you as
                  soon as possible. You can also visit our Franchising page for
                  more information.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="column is-one-quarter"></div>
        </div>
        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}
