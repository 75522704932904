import React from 'react'
import assets from './assets'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description: string
}

export const NoMatch: React.FC<Props> = ({ title, description }) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="hero">
        <h1>
          404 Error - Page Not Found <br />
          Mobile Mint Car Care
        </h1>
      </div>
      <section className="hero is-fullheight mb-5">
        <div className="columns is-vcentered">
          <div className="column fadeLeftMini">
            <h2>
              It's <span className="fire">mmcc</span>
            </h2>
            <p>
              We are working with all our strength to keep up with the
              constantly renewed technology. While following these innovations,
              we need to leave some things behind. That's why you're on this
              page right now.
            </p>
            <p>
              We have learned very well that losers are those who give up. So
              never give up.
            </p>
            <p>
              While we're here, let's talk about the story of this site; The
              color of our name, <span className="fire">mmcc</span>, is the
              color of fire. It always represents enlightenment and progress.
              Other texts are in silver, protecting us from Vampires.
            </p>
            <p>And, Mithril is said to resemble silver highly.</p>
            <p>
              "<span className="fire">Not all those who wander are lost</span>"
            </p>
            <p>
              Sincerely, <span className="fire">mmcc</span>
            </p>
          </div>
          <div className="column fadeRightMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.tolkien}
                alt="J.R.R. Tolkien"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
              />
            </figure>
          </div>
        </div>
        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}
