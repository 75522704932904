import React from 'react'
import assets from './assets'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description: string
}

export const Services: React.FC<Props> = ({ title, description }) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <div className="hero">
        <h1>
          Mobile Car Valeting <br />
          Car Wash Services <br />
          Convenient and Flexible
        </h1>
        <p>
          Our <b>mobile car valeting</b> and <b>car wash services</b> are
          designed to make your life easier, providing a convenient and flexible
          way to keep your car looking like new. Whether you need a{' '}
          <b>car wash</b>, <b>mobile valeting</b>, or{' '}
          <b>car valeting service</b>, our expert team has the knowledge and
          skills to meet your needs. We use only the highest quality cleaning
          products and techniques to ensure a thorough clean, both inside and
          out. Our services are tailored to fit your busy schedule, allowing you
          to book a service at a time and place that works for you.
        </p>
        <h2>
          Mobile Car Wash Services <br />
          Expert Cleaning On The Go
        </h2>
        <p>
          Our <b>mobile car valeting</b> and <b>car wash services</b> are
          perfect for individuals who don't have the time or desire to take
          their car to a traditional
          <b>car wash</b>. Our expert team comes to you, bringing all the
          necessary equipment and products to provide a top-quality service. We
          offer a range of services, including <b>car washing</b>,{' '}
          <b>interior cleaning</b>, and <b>full valet</b> services. With our
          convenient and flexible services, you can keep your car looking like
          new, without sacrificing your precious time. Book now and experience
          the difference of our expert <b>mobile car valeting</b> and{' '}
          <b>car wash services</b>.
        </p>
      </div>
      <section className="hero is-fullheight-with-navbar">
        <div className="columns is-vcentered">
          <div className="column fadeLeftMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.basic}
                alt="Mobile Mint Car Care - Basic Valet"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
              />
            </figure>
          </div>
          <div id="basic" className="column fadeRightMini">
            <h2>Basic Valet</h2>
            <p>
              <b>Exterior wash</b>: A thorough wash and rinse of the car's
              exterior, including the body, wheels, and windows
            </p>
            <p>
              <b>Vacuuming</b>: Cleaning the interior of the car, including the
              carpets, seats, and floor mats.
            </p>
            <p>
              <b>Dash and Console Dusting</b>: Dusting of the dash and console,
              including the steering wheel and interior controls.
            </p>
            <p>
              Windows Cleaning: Cleaning the interior and exterior windows for a
              clear view.
            </p>
            <p>
              <b>Tires and Rims Cleaning</b>: Cleaning the tires and rims to
              give your car a finished look.
            </p>
            <p>
              <b>On the House</b>: Tire pressure check and Top-up windscreen
              fluid
            </p>
            <button
              className="button is-medium btn"
              title="Basic Valet Service Booking"
              onClick={(event) => (window.location.href = '/Booking')}
            >
              Booking
            </button>
          </div>
        </div>

        <hr />

        <div className="columns is-vcentered">
          <div id="full" className="column fadeLeftMini">
            <h2>Full Valet</h2>
            <p>
              <b>Exterior wash</b>: A thorough wash and rinse of the car's
              exterior, including the body, wheels, and windows
            </p>
            <p>
              <b>Vacuuming</b>: Cleaning the interior of the car, including the
              carpets, seats, and floor mats.
            </p>
            <p>
              <b>Dash and Console Dusting</b>: Dusting of the dash and console,
              including the steering wheel and interior controls.
            </p>
            <p>
              Windows Cleaning: Cleaning the interior and exterior windows for a
              clear view.
            </p>
            <p>
              <b>Tires and Rims Cleaning</b>: Cleaning the tires and rims to
              give your car a finished look.
            </p>
            <p>
              <b>Leather Conditioning</b>: Conditioning the leather seats to
              keep them soft and supple.
            </p>
            <p>
              <b>Interior Detailing</b>: Cleaning and detailing of the interior,
              including the dashboard, door panels, and center console.
            </p>
            <p>
              <b>On the House</b>: Tire pressure check and Top-up windscreen
              fluid
            </p>
            <button
              className="button is-medium btn"
              title="Full Valet Service Booking"
              onClick={(event) => (window.location.href = '/Booking')}
            >
              Booking
            </button>
          </div>
          <div className="column fadeRightMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.full}
                alt="Mobile Mint Car Care - Full Valet"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
              />
            </figure>
          </div>
        </div>

        <hr />

        <div className="columns is-vcentered">
          <div className="column fadeLeftMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.ultimate}
                alt="Mobile Mint Car Care - Ultimate Valet"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
              />
            </figure>
          </div>
          <div id="ultimate" className="column fadeRightMini">
            <h2>Ultimate Valet</h2>
            <p>
              <b>Exterior wash</b>: A thorough wash and rinse of the car's
              exterior, including the body, wheels, and windows
            </p>
            <p>
              <b>Vacuuming</b>: Cleaning the interior of the car, including the
              carpets, seats, and floor mats.
            </p>
            <p>
              <b>Dash and Console Dusting</b>: Dusting of the dash and console,
              including the steering wheel and interior controls.
            </p>
            <p>
              Windows Cleaning: Cleaning the interior and exterior windows for a
              clear view.
            </p>
            <p>
              <b>Tires and Rims Cleaning</b>: Cleaning the tires and rims to
              give your car a finished look.
            </p>
            <p>
              <b>Leather Conditioning</b>: Conditioning the leather seats to
              keep them soft and supple.
            </p>
            <p>
              <b>Interior Detailing</b>: Cleaning and detailing of the interior,
              including the dashboard, door panels, and center console.
            </p>
            <p>
              <b>Paint Protection</b>: Applying a paint protection coating to
              help guard against future damage from the elements.
            </p>
            <p>
              <b>Car Fragrance</b>: Adding a pleasant fragrance to the interior
              of the car for a pleasant driving experience.
            </p>
            <p>
              <b>On the House</b>: Tire pressure check and Top-up windscreen
              fluid
            </p>
            <button
              className="button is-medium btn"
              title="Ultimate Valet Service Booking"
              onClick={(event) => (window.location.href = '/Booking')}
            >
              Booking
            </button>
          </div>
        </div>

        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}
