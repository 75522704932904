import React from 'react'
import assets from './assets'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description: string
}

export const Franchising: React.FC<Props> = ({ title, description }) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <section className="hero is-fullheight-with-navbar">
        <div className="hero">
          <h1>
            Join Our Mobile Car Detailing and <br />
            Valeting Business Opportunities
          </h1>
          <p>
            Are you looking for a business opportunity that lets you be your own
            boss and make a real difference in your community? Our franchise
            program may be the perfect solution. We offer comprehensive training
            and support to help you get started with your own{' '}
            <b>mobile car valeting</b> and <b>car wash business</b>, using our
            proven techniques and top-quality cleaning products. With our
            franchise program, you can benefit from a trusted brand, a loyal
            customer base, and a business model that has been tried and tested
            over the years.
          </p>

          <div className="columns is-vcentered">
            <div className="column fadeLeftMini">
              <figure className="image is-256x256">
                <img
                  className="is-rounded"
                  src={assets.bull}
                  alt="Join Our Mobile Car Detailing and Valeting Business Opportunities"
                  width="100%"
                  style={{ opacity: true ? 1 : 1 }}
                />
              </figure>
            </div>
            <div className="column fadeRightMini">
              <h2>
                Experience the Benefits of a Mobile Car Valeting and Car Wash
                Franchise
              </h2>
              <p>
                Our franchise program is ideal for individuals who are
                passionate about cars, customer service, and business success.
                With our support, you can enjoy the benefits of a turnkey
                business model that includes everything you need to get started,
                from training and equipment to marketing and ongoing support.
                You'll also have the freedom and flexibility to set your own
                schedule and build your business on your own terms. Join our
                growing network of franchisees today and discover the rewards of
                owning your own <b>mobile car valeting</b> and{' '}
                <b>car wash business</b>.
              </p>
              <h2>
                Why Choose Our Franchise Program for Mobile Car Valeting and Car
                Wash Services?
              </h2>
              <p>
                Are you interested in starting your own business but don't know
                where to begin? Our franchise program for{' '}
                <b>mobile car valeting</b> and <b>car wash</b>
                services may be the perfect solution. Here are just a few of the
                reasons why you should choose our program:
                <li>
                  <span className="fire">Proven Business Model</span>: Our{' '}
                  <b>mobile car valeting</b> and <b>car wash business</b> model
                  has been developed and refined over the years to ensure
                  maximum efficiency and profitability.
                </li>
                <li>
                  <span className="fire">Comprehensive Training</span>: We offer
                  comprehensive training and ongoing support to help you get
                  started and grow your business with confidence.
                </li>
                <li>
                  <span className="fire">
                    Top-Quality Products and Techniques
                  </span>
                  : Our cleaning products and techniques are the best in the
                  industry, ensuring that your customers receive top-quality
                  services that they will love.
                </li>
                <li>
                  <span className="fire">Flexible Schedule</span>: As a
                  franchisee, you'll have the freedom and flexibility to set
                  your own schedule and build your business on your own terms.
                </li>
                <li>
                  <span className="fire">Trusted Brand</span>: With our trusted
                  brand and loyal customer base, you'll have the support and
                  resources you need to succeed in the competitive{' '}
                  <b>mobile car valeting</b> and car wash market.
                </li>
                Join our franchise program today and experience the benefits of
                owning your own mobile car valeting and car wash business.
              </p>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column fadeLeftMini">
            <h2>What We Offer</h2>
            <li>Established brand and reputation</li>
            <li>Proven business model and systems</li>
            <li>Expert training and ongoing support</li>
            <li>Access to exclusive products and services</li>
            <li>Effective marketing and advertising support</li>
            <hr />
            <h3>Why Choose Mobile Mint Car Care</h3>
            <p>
              Mobile Mint Car Care will be a leader in the{' '}
              <b>Mobile Car Care industry</b>, with a proven track record of
              success and a commitment to providing high-quality services to our
              customers. By franchising with us, you will become part of a
              thriving business that is dedicated to helping its franchisees
              succeed. Our expert training, ongoing support, and established
              brand and reputation make us a great choice for entrepreneurs who
              want to own a successful business in the automotive care industry.{' '}
            </p>
            <hr />
            <h4>How to Get Started</h4>
            <p>
              If you are interested in franchising with{' '}
              <b>Mobile Mint Car Care</b>, we encourage you to fill out our
              franchise application. Our franchising team will review your
              application and get in touch with you to discuss the next steps.
              We look forward to helping you achieve your entrepreneurial goals
              and join the <b>Mobile Mint Car Care</b> family!
            </p>
          </div>
          <div className="column fadeRightMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.bull2}
                alt="Mobile Mint Car Care - Franchising"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
              />
            </figure>
          </div>
        </div>
        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}
