import React, { useState, useEffect } from 'react'
import axios from 'axios'
import assets from './assets'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description: string
}

interface Article {
  title: string
  link: string
  description: string
}

const Latest: React.FC<Props> = ({ title, description }) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [articles, setArticles] = useState<Article[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get('https://blog.mobilemintcarcare.com/feed.xml')
      const parser = new DOMParser()
      const xml = parser.parseFromString(res.data, 'text/xml')
      const items = xml.querySelectorAll('item')
      const articles = Array.from(items).map((item) => {
        const title = item.querySelector('title')?.textContent || ''
        const link = item.querySelector('link')?.textContent || ''
        const description = item.querySelector('description')?.textContent || ''
        return { title, link, description }
      })
      setArticles(articles)
    }

    fetchData()
  }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <div className="hero">
        <h1>
          Stay Up-to-Date with <br />
          the Latest Car Cleaning Trends and Tips
        </h1>
        <p>
          Our Latest News & Articles page is the go-to source for all the latest
          trends and tips in the <b>car cleaning industry</b>. From the best
          products and techniques for <b>car detailing</b> to the latest
          innovations in <b>mobile car wash technology</b>, we keep you
          up-to-date with the latest developments. Our experienced{' '}
          <b>car valeting</b> team shares their expertise and insights,
          providing valuable advice on how to keep your car looking its best.
          Stay tuned for the latest news, <b>expert tips</b>, and exciting
          developments in the <b>car clean industry</b>, as well as updates on
          our latest franchise opportunities and services such as{' '}
          <b>detailing services</b>, <b>mobile valeting</b>, and{' '}
          <b>car wash solutions</b>.
        </p>
      </div>

      <section className="hero is-fullheight-with-navbar">
        <div className="columns is-vcentered">
          <div className="column fadeLeftMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.car}
                alt="Mobile Mint Car Care - Latest News"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
              />
            </figure>
          </div>
          <div className="column fadeRightMini">
            {articles.slice(0, 5).map((article, index) => (
              <div key={index}>
                <li>
                  <a className="latest" href={article.link}>
                    {article.title}
                  </a>
                  <p>{article.description}</p>
                </li>
              </div>
            ))}
          </div>
        </div>
        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}

export default Latest
