import React, { useState } from 'react'
import { PopupButton } from 'react-calendly'
import assets from './assets'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description: string
}

const events = [
  {
    url: 'https://calendly.com/mobilemintcarcare/valet-services',
    name: 'Basic Valet',
    explanation: (
      <>
        <p>
          <b>
            Exterior wash, Vacuuming, Dash and Console Dusting, Windows
            Cleaning, Tires and Rims Cleaning.
          </b>{' '}
          <br /> Please visit{' '}
          <a className="navItem t1" href="/Services">
            Services
          </a>{' '}
          for more information.
        </p>

        <p>
          <li>
            <img src={assets.smallcar} alt="small car" width="50" height="50" />{' '}
            | A + B Class | £ 30
          </li>
          <li>
            <img src={assets.bigcar} alt="big car" width="50" height="50" /> | C
            + D + E Class | £ 35
          </li>
          <li>
            <img src={assets.suvcar} alt="suv car" width="50" height="50" /> |
            SUV + Jeep | £ 40
          </li>
        </p>
        <p>
          <p>
            (<b>app. takes up to 1 Hours</b>)
          </p>
        </p>
      </>
    ),
  },
  {
    url: 'https://calendly.com/mobilemintcarcare/valet-services',
    name: 'Full Valet',
    explanation: (
      <>
        <p>
          Exterior wash, Vacuuming, Dash and Console Dusting, Windows Cleaning,
          Tires and Rims Cleaning, Leather Conditioning, Interior Detailing.
          <br /> Please visit{' '}
          <a className="navItem t1" href="/Services">
            Services
          </a>{' '}
          for more information.
        </p>

        <p>
          <li>
            <img src={assets.smallcar} alt="small car" width="50" height="50" />{' '}
            | A + B Class | £ 40
          </li>
          <li>
            <img src={assets.bigcar} alt="big car" width="50" height="50" /> | C
            + D + E Class | £ 45
          </li>
          <li>
            <img src={assets.suvcar} alt="suv car" width="50" height="50" /> |
            SUV + Jeep | £ 50
          </li>
        </p>
        <p>
          <p>
            (<b>app. takes up to 1.5 Hours</b>).
          </p>
        </p>
      </>
    ),
  },
  {
    url: 'https://calendly.com/mobilemintcarcare/valet-services',
    name: 'Ultimate Valet',
    explanation: (
      <>
        <p>
          Exterior wash, Vacuuming, Dash and Console Dusting, Windows Cleaning,
          Tires and Rims Cleaning, Leather Conditioning, Paint Protection, Car
          Fragrance.
          <br /> Please visit{' '}
          <a className="navItem t1" href="/Services">
            Services
          </a>{' '}
          for more information.
        </p>

        <p>
          <li>
            <img src={assets.smallcar} alt="small car" width="50" height="50" />{' '}
            | A + B Class | £ 70
          </li>
          <li>
            <img src={assets.bigcar} alt="big car" width="50" height="50" /> | C
            + D + E Class | £ 75
          </li>
          <li>
            <img src={assets.suvcar} alt="suv car" width="50" height="50" /> |
            SUV + Jeep | £ 80
          </li>
        </p>
        <p>
          (<b>app. takes up to 2 Hours</b>)
        </p>
      </>
    ),
  },
]

const Booking: React.FC<Props> = ({ title, description }) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [selectedEvent, setSelectedEvent] = useState({
    url: 'https://calendly.com/mobilemintcarcare/valet-services',
    name: (
      <>
        <p>Basic Valet</p>
      </>
    ),
    explanation: (
      <>
        <p>
          Exterior wash, Vacuuming, Dash and Console Dusting, Windows Cleaning,
          Tires and Rims Cleaning.
          <br /> Please visit{' '}
          <a className="navItem t1" href="/Services">
            Services
          </a>{' '}
          for more information.
        </p>

        <p>
          <li>
            <img src={assets.smallcar} alt="small car" width="50" height="50" />{' '}
            | A + B Class | £ 30
          </li>
          <li>
            <img src={assets.bigcar} alt="big car" width="50" height="50" /> | C
            + D + E Class | £ 35
          </li>
          <li>
            <img src={assets.suvcar} alt="suv car" width="50" height="50" /> |
            SUV + Jeep | £ 40
          </li>
        </p>
        <p>
          <p>
            (<b>app. takes up to 1 Hours</b>)
          </p>
        </p>
      </>
    ),
  })
  const rootElement = document.getElementById('root')

  const handleEventSelection = (event: any) => {
    setSelectedEvent(event)
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <section className="hero is-fullheight mb-5">
        <div className="hero">
          <h1>
            Book Your Mobile Car Valeting and <br />
            Car Wash Services Today
          </h1>
        </div>
        <div className="columns">
          <div className="column is-one-quarter"></div>
          <div className="column">
            <p className="fadeRightMini">
              Looking to get your car looking like new again? Look no further
              than our expert <b>mobile car valeting</b> and <b>car wash</b>{' '}
              services. Our team of professionals provides flexible and
              convenient services that cater to your needs, including exterior{' '}
              <b>car washing</b>, <b>interior cleaning</b>, and{' '}
              <b>full valet</b> services. Booking is easy - simply choose the
              service you need, select your preferred time and location, and our
              team will take care of the rest. Don't let a busy schedule keep
              you from having a clean and polished vehicle. Book now and
              experience the difference of our top-quality{' '}
              <b>mobile car valeting</b> and <b>car wash services</b>.
            </p>
            <hr />
            <div className="column">
              <h3 className="has-text-centered">
                <p className="fire"> Please select the service at below </p>

                <svg
                  className="doubleAarrow"
                  width="75"
                  height="75"
                  viewBox="0 0 330 330"
                  style={{ opacity: 0 }}
                >
                  <path
                    id="d1"
                    fill="#e25822"
                    d="M304.394,139.394l-139.39,139.393L25.607,139.393c-5.857-5.857-15.355-5.858-21.213,0.001
		c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150
		c5.858-5.858,5.858-15.355,0-21.213C319.749,133.536,310.251,133.535,304.394,139.394z"
                  />
                  <path
                    id="d2"
                    fill="#e25822"
                    d="M154.398,190.607c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150
		c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0l-139.39,139.393L25.607,19.393
		c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L154.398,190.607z"
                  />
                </svg>
              </h3>
            </div>
          </div>
          <div className="column is-one-quarter"></div>
        </div>

        <div className="column has-text-centered">
          {events.map((event) => (
            <div key={event.name}>
              <h4>
                <input
                  type="radio"
                  name="event"
                  value={event.name}
                  onClick={() => handleEventSelection(event)}
                />

                <label>
                  &nbsp;<span className="fire">{event.name}</span>
                </label>
              </h4>
            </div>
          ))}
        </div>

        <hr />
        <div className="columns has-text-centered">
          <div className="column is-one-fifth"></div>
          {selectedEvent && (
            <div className="column">
              <h2>{selectedEvent.name}</h2>
              <p>{selectedEvent.explanation}</p>
              {rootElement ? (
                <PopupButton
                  className="btn"
                  url={selectedEvent.url}
                  rootElement={rootElement}
                  text="Click here to schedule!"
                />
              ) : (
                <div>Loading...</div>
              )}
            </div>
          )}
          <div className="column is-one-fifth"></div>
        </div>
        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}

export default Booking
