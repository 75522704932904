import React from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description: string
}

export const Test: React.FC<Props> = ({ title, description }) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="hero">
        <h1>Test</h1>
      </div>
      <section className="hero is-fullheight-with-navbar">
        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}
