import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha'
import assets from './assets'
import { Helmet } from 'react-helmet-async'

export const AboutUs = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const form = useRef()
  const [message, setMessage] = useState('')
  const [recaptchaValue, setRecaptchaValue] = useState('')

  const sendEmail = async (e) => {
    e.preventDefault()

    if (!recaptchaValue) {
      return setMessage('Please verify that you are not a robot')
    }

    emailjs
      .sendForm(
        'service_so6mmls',
        'template_3sdgvgt',
        form.current,
        'R3kyQlCSd-urooR12',
      )
      .then(
        (result) => {
          setMessage('Your message has been sent')
        },
        (error) => {
          setMessage('There was an error sending your message')
        },
      )
  }

  return (
    <>
      <Helmet>
        <title>
          About Us - Professional Car Detailing and Mobile Valeting Services
        </title>
        <meta
          name="description"
          content="Get in touch with Mobile Mint Car Care for exceptional car detailing and mobile valeting services. Our experienced team provides interior and exterior cleaning, paint protection."
        />
      </Helmet>
      <section className="hero is-fullheight mb-5">
        <div className="hero">
          <h1>
            About Us <br />
            Professional Car Detailing and <br />
            Mobile Valeting Services
          </h1>
        </div>

        <div className="columns is-vcentered">
          <div className="column has-text-centered fadeLeftMini">
            <figure className="image is-256x256">
              <img
                className="is-rounded"
                src={assets.contact}
                alt="Mobile Mint Car Care - Contact"
                width="100%"
                style={{ opacity: true ? 1 : 1 }}
              />
            </figure>
          </div>
          <div className="column fadeRightMini">
            <h2>
              Welcome to Mobile Mint Car Care - Your One-Stop Shop for Auto
              Detailing
            </h2>
            <p>
              Welcome to the Contact page of Mobile Mint Car Care, your one-stop
              destination for all your car care needs. We are a new company,
              dedicated to providing top-notch car detailing services to our
              customers. Whether you need a full valet, compound, polish and
              wax, or just a quick wash, we've got you covered. Our team of
              experienced professionals is here to ensure that your car is
              always in the best condition.
            </p>

            <div>
              <h3>Get in Touch with Our Team for Expert Service and Support</h3>
              <p>
                If you have any questions or would like to schedule an
                appointment, please don't hesitate to contact us. You can reach
                us by phone, email or by filling out the contact form on this
                page. We will get back to you as soon as possible. Our customer
                service team is always happy to help and answer any questions
                you may have. We are committed to providing excellent customer
                service and ensuring that all of our customers are completely
                satisfied with our services.
              </p>

              <h4>
                Connect with Mobile Mint Car Care for All Your Auto Detailing
                Needs
              </h4>
              <p>
                In addition to our core services, we also offer complimentary
                services such as tyre pressure check and top-up of windscreen
                fluid. We believe that these small touches make a big difference
                in the overall care of your vehicle. We are constantly looking
                for ways to improve and enhance our services to better meet the
                needs of our customers. We look forward to hearing from you and
                providing you with the best car care experience.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <h2 className="has-text-centered">Contact Form</h2>
        <p className="has-text-centered">
          Don't hesitate to reach out to us! Whether you have a question,
          concern, or just want to say hi, we're always happy to hear from you.
          Our contact form is the easiest and quickest way to get in touch, so
          don't be shy and drop us a line today!
        </p>
        <div className="columns">
          <div className="column is-one-quarter"></div>
          <div className="column">
            <form ref={form} onSubmit={sendEmail}>
              {message && <p>{message}</p>}
              <label>Name</label>
              <input
                class="input is-primary has-background-dark has-text-grey-lighter"
                placeholder="Your Name"
                type="text"
                name="user_name"
              />
              <label>Email</label>
              <input
                class="input is-primary has-background-dark has-text-grey-lighter"
                placeholder="Your Email Address"
                type="email"
                name="user_email"
              />
              <label>Message</label>
              <textarea
                class="input is-primary has-background-dark has-text-grey-lighter"
                name="message"
                placeholder="Your Message"
                type="text"
              />
              <ReCAPTCHA
                sitekey="6LeWYoAUAAAAAPxHQosYGPqg8iEJ_4ahRMB8BuBv"
                theme="dark"
                onChange={(value) => setRecaptchaValue(value)}
              />
              <input
                className="button is-medium btn"
                type="submit"
                value="Send"
              />
            </form>
          </div>
          <div className="column is-one-quarter"></div>
        </div>
        <div>
          <button
            className="is-pulled-right btn is-small"
            aria-label="Scroll to Top"
            onClick={handleScrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 4L4 12h8v8h4v-8h8l-8-8z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  )
}
