import React from 'react'
import './navigationbar.css'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../assets/navigation-logo.webp'

export const NavigationBar = () => (
  <>
    <Navbar className="color-nav" expand="lg">
      <Container fluid>
        <Navbar.Brand className="navItem t2" href="/">
          <img
            src={logo}
            alt="Mobile Mint Car Care Logo"
            width="60px"
            height="60px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className="navItem t1" href="/Booking">
              Booking
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/Services">
              Services
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/Franchising">
              Franchising
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/Corporate">
              Corporate
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/FrequentlyAskedQuestions">
              FAQ
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/Latest">
              Latest News
            </Nav.Link>
            <Nav.Link className="navItem t1" href="/AboutUs">
              About & Contact
            </Nav.Link>

            <Nav.Link>
              <button
                className="button is-small btn"
                title="Blog"
                onClick={(event) =>
                  window.open('https://blog.mobilemintcarcare.com')
                }
              >
                Blog
              </button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
)
